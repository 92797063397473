import * as React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import {Button} from "antd";

import {
  collection,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react-core";
import { DataTable } from "@cuba-platform/react-ui";

import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {RouteComponentProps} from "react-router";
import {Lists} from "../../cuba/entities/pasweb_Lists";
import {CountsLink} from "../CountsLink/CountsLink";
import {OwnerRentalSearchForm} from "./OwnerRentalSearchForm";

type Props = { webUser: Webusers } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
class RentalsListComponent extends React.Component<Props>
{
  @observable dataCollection: DataCollectionStore<Lists>;
  @observable selectedRowKey: string | undefined;

  componentDidMount() {
    const customerId = this.props.webUser.customer?.id;
    if (customerId) {
      this.dataCollection = collection<Lists>(Lists.NAME, {
        view: "_local",
        sort: "+title",
        filter: {conditions: [
            {property: "own", operator: "=", value: customerId}
          ]},
      });
    }
  }

  render() {

    if (!this.dataCollection) return null;

    const buttons = [
        <Button
          key="edit"
          htmlType="button"
          style={{ margin: "0 12px 12px 0" }}
          disabled={!this.selectedRowKey}
          type="default"
          onClick={() => { this.props.history.push("/rentalsView/" + this.selectedRowKey)}}
        >
          <FormattedMessage id="common.view" />
        </Button>
    ];

    return (
      <div>
        <OwnerRentalSearchForm {...this.props} />
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={true}
          enableFiltersOnColumns={['title']}
          tableProps={{bordered: true, pagination:{pageSize:1000}}}
          columnDefinitions={[
            {field: "title", columnProps: { title: "List", width: "80%" }},
            {columnProps: {
              render: (text, record : any) => (
                <CountsLink list={record.lstTable}/>
                )
              }
            }
          ]}
          onRowSelectionChange={this.handleRowSelectionChange}
          hideSelectionColumn={true}
          buttons={buttons}
        />
      </div>
    );
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const OwnerRentalsList = injectIntl(RentalsListComponent);

export default OwnerRentalsList;
