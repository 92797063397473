import * as React from "react";
import {Form, Card, Button, Input, notification, Divider, Typography} from "antd";
import {FormComponentProps} from "antd/lib/form";
import {restServices} from "../../cuba/services";
import {cubaREST} from "../../index";

import "../../app/App.css";

const {TextArea} = Input;
const {Title, Text} = Typography;

class ContactFormInternal extends React.Component<{} & FormComponentProps> {

  state = {
    name: '',
    email: '',
    message: '',
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        restServices.pasweb_ContactEmailService.sendEmail(cubaREST)( { name: values.name, email: values.email, message: values.message } )
          .then((result:Boolean) => {
             if (result) {
               notification.success({ message: "Message sent.  We will reply as quickly as possible.  Thank you for your interest!" });
             } else {
               notification.error( { message: "Message send failure.  We apologize for the inconvenience.  Please send a manual email to info@paslists.com.  Thank you for your interest!"});
             }
          });
      }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;

    return(
      <Card className="narrow-layout" >
          <Form onSubmit={this.handleSubmit} className="contact-form">
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please enter your name'}],
            })(
              <Input placeholder="Your Name" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please enter your email address' },
                      { type: "email", message: 'Please enter a valid email address'},
              ],
            })(
              <Input placeholder="Your Email Address" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Please enter the message'}],
            })(
              <TextArea rows={4} cols={16}  placeholder="Message" />
            )}
          </Form.Item>
          <Button type="primary" htmlType="submit" >Send</Button>
        </Form>
        <Divider/>
        <Title level={4}>Professional Advertising Systems Inc.</Title>
        <Text>3 Morgan Drive #310</Text>
        <br/>
        <Text>Mt. Kisco, NY 10549</Text>
        <br/>
        <Text>914-765-0500</Text>
        <br/>
        <Text>info@paslists.com</Text>
      </Card>
    )
  }

}

export const ContactForm = Form.create()(ContactFormInternal);


