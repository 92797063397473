import { BaseLongIdEntity } from "./base/sys$BaseLongIdEntity";
import { Orders } from "./pasweb_Orders";
import { Customers } from "./pasweb_Customers";
import { OrderStatusEnum } from "../enums/enums";
export class MPStatus extends BaseLongIdEntity {
  static NAME = "pasweb_MPStatus";
  order?: Orders | null;
  ordType?: string | null;
  customer?: Customers | null;
  bkrnum?: string | null;
  orddate?: any | null;
  wantdate?: any | null;
  cutoffdate?: any | null;
  rundate?: any | null;
  shipdate?: any | null;
  maildate?: any | null;
  totQtyOrdered?: any | null;
  totQtyReceived?: number | null;
  totQtyConverted?: any | null;
  status?: OrderStatusEnum | null;
  statusDecode?: number | null;
  statdesc?: string | null;
  mergeqty?: any | null;
  outside?: any | null;
  house?: any | null;
  suppress?: any | null;
  unreceived?: any | null;
  received?: any | null;
  testing?: any | null;
  running?: any | null;
  errors?: any | null;
  converted?: any | null;
  iacout?: any | null;
  iacback?: any | null;
  ok?: any | null;
  curstep?: any | null;
  execscript?: string | null;
}
export type MPStatusViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "mpstatus-view";
export type MPStatusView<V extends MPStatusViewName> = V extends "_base"
  ? Pick<
      MPStatus,
      | "id"
      | "ordType"
      | "bkrnum"
      | "orddate"
      | "wantdate"
      | "cutoffdate"
      | "rundate"
      | "shipdate"
      | "maildate"
      | "status"
      | "statusDecode"
      | "statdesc"
      | "mergeqty"
      | "outside"
      | "house"
      | "suppress"
      | "unreceived"
      | "received"
      | "testing"
      | "running"
      | "errors"
      | "converted"
      | "iacout"
      | "iacback"
      | "ok"
      | "curstep"
      | "execscript"
    >
  : V extends "_local"
  ? Pick<
      MPStatus,
      | "id"
      | "ordType"
      | "bkrnum"
      | "orddate"
      | "wantdate"
      | "cutoffdate"
      | "rundate"
      | "shipdate"
      | "maildate"
      | "status"
      | "statusDecode"
      | "statdesc"
      | "mergeqty"
      | "outside"
      | "house"
      | "suppress"
      | "unreceived"
      | "received"
      | "testing"
      | "running"
      | "errors"
      | "converted"
      | "iacout"
      | "iacback"
      | "ok"
      | "curstep"
      | "execscript"
    >
  : V extends "_minimal"
  ? Pick<MPStatus, "id">
  : V extends "mpstatus-view"
  ? Pick<
      MPStatus,
      | "id"
      | "ordType"
      | "bkrnum"
      | "orddate"
      | "wantdate"
      | "cutoffdate"
      | "rundate"
      | "shipdate"
      | "maildate"
      | "status"
      | "statusDecode"
      | "statdesc"
      | "mergeqty"
      | "outside"
      | "house"
      | "suppress"
      | "unreceived"
      | "received"
      | "testing"
      | "running"
      | "errors"
      | "converted"
      | "iacout"
      | "iacback"
      | "ok"
      | "curstep"
      | "execscript"
      | "order"
      | "customer"
      | "totQtyOrdered"
      | "totQtyReceived"
      | "totQtyConverted"
    >
  : never;
