import * as React from "react";
import {Divider} from "antd";

type Props = { ordnum: number, bkrnum: string }
type State = { isLoaded: boolean, url : string, download : string, status: string }

export class MailerReportsLink extends React.Component<Props, State> {

  _isMounted = false;

  constructor(props: Props) {
    super(props)
    this.state = {
      isLoaded: false,
      url:"https://storage.googleapis.com/website-paslists/mergerpts/" + this.pad(this.props.ordnum, 7) + "/mergerpts.htm",
      download: "https://storage.googleapis.com/website-paslists/mergerpts/" + this.pad(this.props.ordnum, 7) + "/" + encodeURIComponent(this.props.bkrnum) + ".reports.zip",
      status: ""
    }
  }

  componentDidMount() {
    this._isMounted = true;

    fetch(this.state.url, {method: 'HEAD'})
    .then(res => {
        if (this._isMounted) {
          if (res.ok) {
            this.setState({
              isLoaded: true,
              status: 'url'
            })
          } else {
            this.setState({
              isLoaded: true,
              status: "No reports available"
            })
          }
        }
      },
        error => {
          if (this._isMounted) {
            this.setState({
              isLoaded: true,
              status: "No reports available (error)"
            })
            console.log("fetchReportsLink promise error: " + error)
          }
        }
    );
  }

  UNSAFE_componentWillUnmount() {
    this._isMounted = false;
  }

  pad(num : number, size : number) : string {
    return ('0000000000' + num).substr(-size)
  }

  render() {
    const {isLoaded, status, url, download} = this.state;
    if (!isLoaded) {
      return null;
    }
    else if (status === 'url') {
      return (
        <span><a target="_blank" rel="noopener noreferrer" href={url}>Reports: View</a>&nbsp;<Divider type="vertical"/>&nbsp;<a target="_blank" rel="noopener noreferrer" href={download}>Download</a></span>
      )
    } else {
      return (<div>{status}</div>)
    }
  }

}
