import { getMenuItems } from "@cuba-platform/react-core";
import SecureArea from "./app/secureArea/SecureArea";
import MergesView from "./app/merges/MergesView";
import MailerMergesList from "./app/merges/MailerMergesList";
import BrokerMailerMergesList from "./app/merges/BrokerMailerMergesList";
import RentalsView from "./app/rentals/RentalsView";;

export const menuItems = getMenuItems();

// Push Route Items to menu config
menuItems.push({
  pathPattern: "/#merge",
  menuLink: "/#merge",
  component: null,
  caption: "Merge/Purge"
});
menuItems.push({
  pathPattern: "/#clean",
  menuLink: "/#clean",
  component: null,
  caption: "Address Cleansing"
});
menuItems.push({
  pathPattern: "/#imb",
  menuLink: "/#imb",
  component: null,
  caption: "Mail Tracking"
});
menuItems.push({
  pathPattern: "/#rentals",
  menuLink: "/#rentals",
  component: null,
  caption: "List Rental"
});
menuItems.push({
  pathPattern: "/#dbmaint",
  menuLink: "/#dbmaint",
  component: null,
  caption: "DB Maintenance"
});
menuItems.push({
  pathPattern: "/#rade",
  menuLink: "/#rade",
  component: null,
  caption: "Response Analysis"
});

menuItems.push({
  pathPattern: "/mergesList",
  menuLink: "",
  component: MailerMergesList,
  caption: ""
});

menuItems.push({
  pathPattern: "/mergesView/:entityId/:brkId?",
  menuLink: "",
  component: MergesView,
  caption: ""
});

menuItems.push({
  pathPattern: "/rentalsView/:entityId",
  menuLink: "",
  component: RentalsView,
  caption: ""
});

menuItems.push({
  pathPattern: "/secureArea",
  menuLink: "/secureArea",
  component: SecureArea,
  caption: "Log In"
});

menuItems.push({
  pathPattern: "/brokerMailerMergesListView/:cusId/:brkId",
  menuLink: "",
  component: BrokerMailerMergesList,
  caption: ""
});

