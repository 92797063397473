import { BaseStringIdEntity } from "./base/sys$BaseStringIdEntity";
import { BrokerMailerSH } from "./pasweb_BrokerMailerSH";
export class BrokerMailer extends BaseStringIdEntity {
  static NAME = "pasweb_BrokerMailer";
  id?: string;
  brkId?: any | null;
  brokername?: string | null;
  cusId?: any | null;
  customername?: string | null;
  numords?: any | null;
  brokerMailerSHList?: BrokerMailerSH[] | null;
}
export type BrokerMailerViewName = "_base" | "_local" | "_minimal";
export type BrokerMailerView<V extends BrokerMailerViewName> = V extends "_base"
  ? Pick<
      BrokerMailer,
      "id" | "brokername" | "customername" | "brkId" | "cusId" | "numords"
    >
  : V extends "_local"
  ? Pick<
      BrokerMailer,
      "id" | "brkId" | "brokername" | "cusId" | "customername" | "numords"
    >
  : V extends "_minimal"
  ? Pick<BrokerMailer, "id" | "brokername" | "customername">
  : never;
