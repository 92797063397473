import { BaseLongIdEntity } from "./base/sys$BaseLongIdEntity";
import { WebUserTypes } from "../enums/enums";
import { Customers } from "./pasweb_Customers";
import { Mailers } from "./pasweb_Mailers";
import { Brokers } from "./pasweb_Brokers";
import { Salesreps } from "./pasweb_Salesreps";
export class Webusers extends BaseLongIdEntity {
  static NAME = "pasweb_Webusers";
  login?: string | null;
  password?: string | null;
  email?: string | null;
  usertype?: string | null;
  newtype?: WebUserTypes | null;
  customer?: Customers | null;
  mailer?: Mailers | null;
  custpage?: string | null;
  broker?: Brokers | null;
  owner?: Customers | null;
  salesrep?: Salesreps | null;
}
export type WebusersViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "webusers-view";
export type WebusersView<V extends WebusersViewName> = V extends "_base"
  ? Pick<
      Webusers,
      | "id"
      | "login"
      | "password"
      | "email"
      | "usertype"
      | "newtype"
      | "custpage"
    >
  : V extends "_local"
  ? Pick<
      Webusers,
      | "id"
      | "login"
      | "password"
      | "email"
      | "usertype"
      | "newtype"
      | "custpage"
    >
  : V extends "webusers-view"
  ? Pick<
      Webusers,
      | "id"
      | "login"
      | "password"
      | "email"
      | "usertype"
      | "newtype"
      | "custpage"
      | "customer"
      | "mailer"
      | "broker"
      | "owner"
      | "salesrep"
    >
  : never;
