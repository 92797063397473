import * as React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { CSVLink } from "react-csv";

import { observable } from "mobx";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  MainStoreInjected,
  injectMainStore, collection, instance
} from "@cuba-platform/react-core";

import "../../app/App.css";

import {Ordstatus} from "../../cuba/entities/pasweb_Ordstatus";
import Moment from "moment";
import {DataTable} from "@cuba-platform/react-ui";
import {Ordlines} from "../../cuba/entities/pasweb_Ordlines";
import {Lists} from "../../cuba/entities/pasweb_Lists";

type Props =  RouteComponentProps<ViewerProps> & MainStoreInjected & WrappedComponentProps;

type ViewerProps = {
  entityId: string;
};

@injectMainStore
@observer
class RentalsViewComponent extends React.Component<Props> {

  @observable dataCollection = collection<Ordstatus>(Ordstatus.NAME, {
    view: "ordstatus-view",
    sort: "-order",
    filter: {conditions: [
        {property: "cancelled", operator: "=", value:"1"},
        {property: "lst", operator: "=", value: this.props.match.params.entityId }
      ]}
  });

  @observable listInstance = instance<Lists>(Lists.NAME, {
    view: "_local",
    loadImmediately: false
  });

  componentDidMount() {
    this.listInstance.load(this.props.match.params.entityId);
  }

  render() {

    if (!this.dataCollection || !this.listInstance) return null;

    const expandRow = ( ordnum: number, expanded:boolean ) => {

      if (expanded) {

        const dataCollection2 = collection<Ordlines>(Ordlines.NAME, {
          view: "ordlines-view",
          sort: "+linenum",
          filter: {
            conditions: [
              {property: "ordOrdnum", operator: "=", value: ordnum}
            ]
          }
        });

        return (<DataTable
          dataCollection={dataCollection2}
          canSelectRowByClick={false}
          tableProps={{bordered: true}}
          columnDefinitions={[
            {field: "keycode", columnProps: {title: "Keycode"}},
            {field: "orlDesc", columnProps: {title: "Segment"}},
            {field: "ordqty",
              columnProps: {
                title: "Order Qty",
                render: (text, record: any) => (record.ordqty == null ? 0 : record.ordqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {field: "shipqty",
              columnProps: {
                title: "Ship Qty",
                render: (text, record: any) => (record.shipqty == null ? 0 : record.shipqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {field: "rentalemail",
              columnProps: {
                title: "Email",
                render: (text, record: any) => ( record.rentalemail?.id)
            }}
          ]}
          hideSelectionColumn={true}
        />);
      } else {
        return null;
      }
    }

    const buttons = [
         <CSVLink key="csvlink"
                  className="ant-btn ant-btn-primary"
                  data={this.dataCollection.items.map( item => {
                     return {
                       ordnum:   item.id,
                       mgrnum:   item.mgrnum,
                       bkrnum:   item.bkrnum,
                       mailer:   item.mailer,
                       orddate:  item.orddate,
                       maildate: item.maildate,
                       wantdate: item.wantdate,
                       shipdate: item.shipdate,
                       status:   item.statdesc,
                       pasdecoy: item.pasdecoy,
                       mgrdecoy: item.mgrdecoy
                   }
                  })}
                  headers={[
                    { label: "PAS Num", key: "ordnum" },
                    { label: "Mgr Num", key: "mgrnum"},
                    { label: "Broker PO", key: "bkrnum" },
                    { label: "Mailer", key: "mailer"},
                    { label: "Order Date", key: "orddate"},
                    { label: "Mail Date", key: "maildate"},
                    { label: "Want Date", key: "wantdate"},
                    { label: "Ship Date", key: "shipdate"},
                    { label: "Status", key: "status" },
                    { label: "PAS Decoy", key: "pasdecoy" },
                    { label: "Mgr Decoy", key: "mgrdecoy"}
                  ]}
                  filename={"rentals_" + this.listInstance.item?.lstTable + "_export.xlsx"}
         >
          Excel
        </CSVLink>
    ];

    return (
      <Modal width="90vw"
             destroyOnClose={true}
             centered={true}
             cancelButtonProps={{ disabled: true}}
             okText="Back"
             onOk={() => { this.props.history.go(-1) }}
             closable={false}
             visible={true}
             title={"Rental Orders for " + this.listInstance.item?.title}
      >
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={false}
          tableProps={{ bordered: true,
            expandedRowRender : (record : any, index, indent, expanded ) => { return expandRow(record.order.id, expanded)}
          }}
          columnDefinitions={[
            {field: "order", columnProps: { title: "PAS Num", render: (text, record: any) => ( record.order.id) }},
            {field: "mgrnum", columnProps: {title: "Mgr Num"}},
            {field: "bkrnum", columnProps: {title: "Broker PO"}},
            {field: "orddate", columnProps: { title: "Order Date", render: (text, record: any) => ( record.orddate == null? "": Moment(record.orddate).format("MM/DD/YYYY"))}},
            {field: "maildate", columnProps: { title: "Mail Date", render: (text, record: any) => ( record.maildate == null? "": Moment(record.maildate).format("MM/DD/YYYY"))}},
            {field: "wantdate", columnProps: { title: "Want Date", render: (text, record: any) => ( record.wantdate == null? "": Moment(record.wantdate).format("MM/DD/YYYY"))}},
            {field: "shipdate", columnProps: { title: "Ship Date", render: (text, record: any) => ( record.shipdate == null? "": Moment(record.shipdate).format("MM/DD/YYYY"))}},
            {field: "statdesc", columnProps: {title: "Status"}},
            {field: "pasdecoy", columnProps: {title: "PAS Decoy"}},
            {field: "mgrdecoy", columnProps: {title: "Mgr Decoy"}}
          ]}
          hideSelectionColumn={true}
          buttons={buttons}
        />
      </Modal>
    );
  }

}

const RentalsView = injectIntl(RentalsViewComponent);
export default RentalsView;
