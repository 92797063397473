import * as React from "react";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import { injectIntl, WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {DataTable} from "@cuba-platform/react-ui";

import {Consultantowner} from "../../cuba/entities/pasweb_Consultantowner";
import ConsultantRentalsList from "./ConsultantRentalsList";
import {ConsultantRentalSearchForm} from "./ConsultantRentalSearchForm";

type Props = { webUser: Webusers } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
class ConsultantOwnerListComponent extends React.Component<Props>
{
  @observable dataCollection: DataCollectionStore<Consultantowner>;
  @observable consId: string;
  @observable cusId: string;

  componentDidMount() {
    if (this.props.webUser.salesrep?.id) {
      this.consId = this.props.webUser.salesrep.id;
      this.dataCollection = collection<Consultantowner>(Consultantowner.NAME, {
        view: "_local",
        sort: "+cusName",
        filter: {
          conditions: [
            {property: "consId", operator: "=", value: this.consId}
          ]
        },
      });
    }
  }

  expandRow = ( cusId: string, expanded:boolean ) => {
    if (expanded) {
      return( <ConsultantRentalsList {...this.props} cusId={cusId}  /> )
    } else {
      return null;
    }
  }

  render() {

    if (!this.dataCollection) return null;

    return (
      <div>
        <ConsultantRentalSearchForm {...this.props} consId={this.consId}/>
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={true}
          enableFiltersOnColumns={['cusName']}
          tableProps={{bordered: true, pagination:{pageSize:1000},
            expandedRowRender : (record : any, index, indent, expanded ) => {
              return this.expandRow(record.cusId, expanded)
            }
          }}
          columnDefinitions={[
            {field: "cusName", columnProps: {title: "List Owner"}}
          ]}
          hideSelectionColumn={true}
        />
        <label defaultValue="" style={{ height: 10 }}/>
      </div>
    );
  }

}

const ConsultantOwnerList = injectIntl(ConsultantOwnerListComponent);

export default ConsultantOwnerList;