import { BaseLongIdEntity } from "./base/sys$BaseLongIdEntity";
import { Brokers } from "./pasweb_Brokers";
import { Orders } from "./pasweb_Orders";
export class Mplists extends BaseLongIdEntity {
  static NAME = "pasweb_Mplists";
  id?: string;
  actqty?: any | null;
  brk?: Brokers | null;
  brokerpo?: string | null;
  cvuser?: string | null;
  datechecked?: any | null;
  daterecd?: any | null;
  dateused?: any | null;
  exchLstid?: any | null;
  exchMlrid?: any | null;
  family?: string | null;
  fqtyambig?: number | null;
  fqtyfemale?: number | null;
  fqtymale?: number | null;
  fqtyunk?: number | null;
  iacdone?: any | null;
  iacsent?: any | null;
  inputqty?: number | null;
  keepcass?: boolean | null;
  keycode?: string | null;
  list?: string | null;
  mplType?: string | null;
  ncoaflag?: boolean | null;
  okdate?: any | null;
  okuser?: string | null;
  ordOrdnum?: Orders | null;
  ordqty?: any | null;
  priority?: any | null;
  prmseg?: string | null;
  qtyambig?: number | null;
  qtyfemale?: number | null;
  qtyflag?: string | null;
  qtymale?: number | null;
  qtyunk?: number | null;
  recdqty?: number | null;
  resplist?: string | null;
  respseg?: string | null;
  reusejob?: any | null;
  reusekey?: string | null;
  reusetype?: string | null;
  segment?: string | null;
  status?: string | null;
  swapordnum?: number | null;
  tap?: any | null;
  statusDesc?: string | null;
}
export type MplistsViewName = "_base" | "_local" | "_minimal" | "mplists-view";
export type MplistsView<V extends MplistsViewName> = V extends "_base"
  ? Pick<
      Mplists,
      | "id"
      | "actqty"
      | "brokerpo"
      | "cvuser"
      | "datechecked"
      | "daterecd"
      | "dateused"
      | "exchLstid"
      | "exchMlrid"
      | "family"
      | "fqtyambig"
      | "fqtyfemale"
      | "fqtymale"
      | "fqtyunk"
      | "iacdone"
      | "iacsent"
      | "inputqty"
      | "keepcass"
      | "keycode"
      | "list"
      | "mplType"
      | "ncoaflag"
      | "okdate"
      | "okuser"
      | "ordqty"
      | "priority"
      | "prmseg"
      | "qtyambig"
      | "qtyfemale"
      | "qtyflag"
      | "qtymale"
      | "qtyunk"
      | "recdqty"
      | "resplist"
      | "respseg"
      | "reusejob"
      | "reusekey"
      | "reusetype"
      | "segment"
      | "status"
      | "swapordnum"
      | "tap"
    >
  : V extends "_local"
  ? Pick<
      Mplists,
      | "id"
      | "actqty"
      | "brokerpo"
      | "cvuser"
      | "datechecked"
      | "daterecd"
      | "dateused"
      | "exchLstid"
      | "exchMlrid"
      | "family"
      | "fqtyambig"
      | "fqtyfemale"
      | "fqtymale"
      | "fqtyunk"
      | "iacdone"
      | "iacsent"
      | "inputqty"
      | "keepcass"
      | "keycode"
      | "list"
      | "mplType"
      | "ncoaflag"
      | "okdate"
      | "okuser"
      | "ordqty"
      | "priority"
      | "prmseg"
      | "qtyambig"
      | "qtyfemale"
      | "qtyflag"
      | "qtymale"
      | "qtyunk"
      | "recdqty"
      | "resplist"
      | "respseg"
      | "reusejob"
      | "reusekey"
      | "reusetype"
      | "segment"
      | "status"
      | "swapordnum"
      | "tap"
    >
  : V extends "mplists-view"
  ? Pick<
      Mplists,
      | "id"
      | "actqty"
      | "brokerpo"
      | "cvuser"
      | "datechecked"
      | "daterecd"
      | "dateused"
      | "exchLstid"
      | "exchMlrid"
      | "family"
      | "fqtyambig"
      | "fqtyfemale"
      | "fqtymale"
      | "fqtyunk"
      | "iacdone"
      | "iacsent"
      | "inputqty"
      | "keepcass"
      | "keycode"
      | "list"
      | "mplType"
      | "ncoaflag"
      | "okdate"
      | "okuser"
      | "ordqty"
      | "priority"
      | "prmseg"
      | "qtyambig"
      | "qtyfemale"
      | "qtyflag"
      | "qtymale"
      | "qtyunk"
      | "recdqty"
      | "resplist"
      | "respseg"
      | "reusejob"
      | "reusekey"
      | "reusetype"
      | "segment"
      | "status"
      | "swapordnum"
      | "tap"
      | "brk"
      | "statusDesc"
      | "ordOrdnum"
    >
  : never;
