import { BaseStringIdEntity } from "./base/sys$BaseStringIdEntity";
export class Consultantowner extends BaseStringIdEntity {
  static NAME = "pasweb_Consultantowner";
  id?: string;
  consId?: any | null;
  consName?: string | null;
  cusId?: any | null;
  cusName?: string | null;
}
export type ConsultantownerViewName = "_base" | "_local" | "_minimal";
export type ConsultantownerView<
  V extends ConsultantownerViewName
> = V extends "_base"
  ? Pick<Consultantowner, "id" | "consId" | "consName" | "cusId" | "cusName">
  : V extends "_local"
  ? Pick<Consultantowner, "id" | "consId" | "consName" | "cusId" | "cusName">
  : never;
