import * as React from "react";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import { injectIntl, WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {DataTable} from "@cuba-platform/react-ui";

import {BrokerMailer} from "../../cuba/entities/pasweb_BrokerMailer";
import BrokerMailerMergesList from "./BrokerMailerMergesList";
import {BrokerMergeSearchForm} from "./BrokerMergeSearchForm";

type Props = { webUser: Webusers } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
class BrokerMailerListComponent extends React.Component<Props>
{
  @observable dataCollection: DataCollectionStore<BrokerMailer>;
  @observable cusId: string;
  @observable brkId: string;

  componentDidMount() {
    if (this.props.webUser.broker?.id) {
      this.brkId = this.props.webUser.broker.id;
      this.dataCollection = collection<BrokerMailer>(BrokerMailer.NAME, {
        view: "_local",
        sort: "+customername",
        filter: {
          conditions: [
            {property: "brkId", operator: "=", value: this.brkId}
          ]
        },
      });
    }
  }

  expandRow = ( cusId: string, brkId:string, expanded:boolean ) => {
    if (expanded) {
      return( <BrokerMailerMergesList {...this.props} cusId={cusId} brkId={brkId} /> )
    } else {
      return null;
    }
  }

  render() {

    if (!this.dataCollection) return null;

    return (
      <div>
        <BrokerMergeSearchForm {...this.props} brkId={this.brkId}/>
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={true}
          enableFiltersOnColumns={['customername']}
          tableProps={{bordered: true, pagination: {pageSize:1000},
            expandedRowRender : (record : any, index, indent, expanded ) => {
              return this.expandRow(record.cusId, record.brkId, expanded)
            }
          }}
          columnDefinitions={[
            {field: "customername", columnProps: {title: "Mailer"}}
          ]}
          hideSelectionColumn={true}
        />
        <label defaultValue="" style={{ height: 10 }}/>
      </div>
    );
  }

}

const BrokerMailerList = injectIntl(BrokerMailerListComponent);

export default BrokerMailerList;