import en from "./en.json";
import enUs from "antd/es/locale/en_US";

export const antdLocaleMapping = {
  en: enUs
};

export const messagesMapping = {
  en: en
};
