import { BaseStringIdEntity } from "./base/sys$BaseStringIdEntity";
import { Brokers } from "./pasweb_Brokers";
import { Customers } from "./pasweb_Customers";
import { Orders } from "./pasweb_Orders";
export class BrokerMailerSH extends BaseStringIdEntity {
  static NAME = "pasweb_BrokerMailerSH";
  id?: string;
  broker?: Brokers | null;
  brokername?: string | null;
  customer?: Customers | null;
  customername?: string | null;
  order?: Orders | null;
  ordnum?: any | null;
  orddate?: any | null;
  cutoffdate?: any | null;
  wantdate?: any | null;
  maildate?: any | null;
  shipdate?: any | null;
  bkrnum?: string | null;
  totQtyOrdered?: any | null;
  totQtyReceived?: any | null;
  totQtyConverted?: any | null;
}
export type BrokerMailerSHViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "brokerMailerSH-view";
export type BrokerMailerSHView<
  V extends BrokerMailerSHViewName
> = V extends "_base"
  ? Pick<
      BrokerMailerSH,
      | "id"
      | "brokername"
      | "customername"
      | "orddate"
      | "cutoffdate"
      | "wantdate"
      | "maildate"
      | "shipdate"
      | "bkrnum"
      | "totQtyOrdered"
      | "totQtyReceived"
      | "totQtyConverted"
    >
  : V extends "_local"
  ? Pick<
      BrokerMailerSH,
      | "id"
      | "brokername"
      | "customername"
      | "orddate"
      | "cutoffdate"
      | "wantdate"
      | "maildate"
      | "shipdate"
      | "bkrnum"
      | "totQtyOrdered"
      | "totQtyReceived"
      | "totQtyConverted"
    >
  : V extends "_minimal"
  ? Pick<BrokerMailerSH, "id">
  : V extends "brokerMailerSH-view"
  ? Pick<
      BrokerMailerSH,
      | "id"
      | "brokername"
      | "customername"
      | "orddate"
      | "cutoffdate"
      | "wantdate"
      | "maildate"
      | "shipdate"
      | "bkrnum"
      | "totQtyOrdered"
      | "totQtyReceived"
      | "totQtyConverted"
      | "broker"
      | "customer"
      | "order"
      | "ordnum"
    >
  : never;
