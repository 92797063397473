import { BaseLongIdEntity } from "./base/sys$BaseLongIdEntity";
import { Orders } from "./pasweb_Orders";
import { RentalEmails } from "./pasweb_RentalEmails";
import { Shiptos } from "./pasweb_Shiptos";
import { Selects } from "./pasweb_Selects";
export class Ordlines extends BaseLongIdEntity {
  static NAME = "pasweb_Ordlines";
  contact?: string | null;
  keycode?: string | null;
  keyrec?: boolean | null;
  material?: string | null;
  nthflag?: string | null;
  ordqty?: any | null;
  orlDesc?: string | null;
  price?: any | null;
  linenum?: any | null;
  ordOrdnum?: any | null;
  order?: Orders | null;
  rentalemail?: RentalEmails | null;
  shipchg?: any | null;
  shipqty?: any | null;
  shipvia?: string | null;
  shp?: Shiptos | null;
  tracknum?: string | null;
  unit?: string | null;
  selects?: Selects[] | null;
}
export type OrdlinesViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "ordlines-view";
export type OrdlinesView<V extends OrdlinesViewName> = V extends "_base"
  ? Pick<
      Ordlines,
      | "id"
      | "order"
      | "linenum"
      | "contact"
      | "keycode"
      | "keyrec"
      | "material"
      | "nthflag"
      | "ordqty"
      | "orlDesc"
      | "price"
      | "ordOrdnum"
      | "shipchg"
      | "shipqty"
      | "shipvia"
      | "tracknum"
      | "unit"
    >
  : V extends "_local"
  ? Pick<
      Ordlines,
      | "id"
      | "contact"
      | "keycode"
      | "keyrec"
      | "material"
      | "nthflag"
      | "ordqty"
      | "orlDesc"
      | "price"
      | "linenum"
      | "ordOrdnum"
      | "shipchg"
      | "shipqty"
      | "shipvia"
      | "tracknum"
      | "unit"
    >
  : V extends "_minimal"
  ? Pick<Ordlines, "id" | "order" | "linenum">
  : V extends "ordlines-view"
  ? Pick<
      Ordlines,
      | "id"
      | "order"
      | "linenum"
      | "keycode"
      | "ordqty"
      | "orlDesc"
      | "shipqty"
      | "ordOrdnum"
      | "rentalemail"
    >
  : never;
