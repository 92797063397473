import React from "react";
import {observer} from "mobx-react";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import {RouteComponentProps} from "react-router";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Tabs, Typography} from "antd";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {WebUserTypes} from "../../cuba/enums/enums";
import MailerMergesList from "../merges/MailerMergesList";
import OwnerRentalsList from "../rentals/OwnerRentalsList";
import BrokerMailerList from "../merges/BrokerMailerList";
import ConsultantMailerList from "../merges/ConsultantMailerList";
import ConsultantOwnerList from "../rentals/ConsultantOwnerList";

const { TabPane } = Tabs;
const { Title } = Typography;

type Props = {webUser: Webusers} & RouteComponentProps & MainStoreInjected & WrappedComponentProps;

@injectMainStore
@observer
class SecureContent extends React.Component<Props> {

  render() {

    if (this.props.webUser == null) return;

    const { newtype } = this.props.webUser;
    if (newtype === WebUserTypes.MAILER) {
      return (
        <div>
          <Tabs type="card">
            <TabPane tab="Merges" key="Merges">
              <MailerMergesList {...this.props} />
            </TabPane>
          </Tabs>
        </div>
      );
    } else if (newtype === WebUserTypes.OWNER) {
      return (
        <div>
          <TabPane tab="Rentals" key="Rentals">
            <OwnerRentalsList {...this.props}/>
          </TabPane>
        </div>
      );
    } else if (newtype === WebUserTypes.MAILEROWNER) {
      return (
        <div>
          <Tabs type="card">
            <TabPane tab="Merges" key="Merges">
              <MailerMergesList {...this.props} />
            </TabPane>
            <TabPane tab="Rentals" key="Rentals">
              <OwnerRentalsList {...this.props}/>
            </TabPane>
          </Tabs>
        </div>
      );
    } else if (newtype === WebUserTypes.BROKER) {
      return (
        <div>
          <Tabs type="card">
            <TabPane tab="Mailers" key="Mailers">
              <BrokerMailerList {...this.props}/>
            </TabPane>
          </Tabs>
        </div>
      )
    } else if (newtype === WebUserTypes.BROKERMGR) {
      return (
        <div>
          <Tabs type="card">
            <TabPane tab="Mailers" key="Mailers">
              <BrokerMailerList {...this.props}/>
            </TabPane>
            <TabPane tab="Rentals" key="Rentals">
              <OwnerRentalsList {...this.props}/>
            </TabPane>
          </Tabs>
        </div>
      )
    } else if (newtype === WebUserTypes.CONSULTANT) {
      return (
        <div>
          <Tabs  type="card">
            <TabPane tab="Mailers" key="Mailers">
              <ConsultantMailerList {...this.props}/>
            </TabPane>
            <TabPane tab="Owners" key="Owners">
              <ConsultantOwnerList {...this.props}/>
            </TabPane>
          </Tabs>
        </div>
      );
    } else {
      return (
        <Title level={1}>Can't locate user - log in failed.</Title>
      );
    }
  }

}

export default injectIntl(SecureContent);
