import * as React from "react";
import { ChangeEvent, FormEvent } from "react";
import { Form, Icon, Input, message, Modal } from "antd";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react-core";
import { RouteComponentProps  } from "react-router";
import "./Login.css";
import logo from "./logo.png";
import { LanguageSwitcher } from "../../i18n/LanguageSwitcher";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";

type props = {
  title: string;
}

@injectMainStore
@observer
class ModalLogin extends React.Component<MainStoreInjected & WrappedComponentProps & RouteComponentProps & props> {
  @observable login: string;
  @observable password: string;
  @observable performingLoginRequest = false;
  @observable visible = true;

  @action
  changeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    this.login = e.target.value;
  };

  @action
  changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.password = e.target.value;
  };

  @action
  doLogin = (e: FormEvent) => {
    const history = this.props.history;

    e.preventDefault();
    this.performingLoginRequest = true;
    this.props
      .mainStore!.login(this.login, this.password)
      .then(
        action(() => {
          this.performingLoginRequest = false;
          this.visible = false;
          history.push("/#home");
        })
      )
      .catch(
        action(() => {
          this.performingLoginRequest = false;
          message.error(this.props.intl.formatMessage({ id: "login.failed" }));
        })
      );
  };

  doCancel = (e: FormEvent) => {
    const history = this.props.history;
    e.preventDefault();
    this.visible = false;
    history.push("/#home");
  }

  render() {
    return (
        <Modal title={this.props.title} visible={this.visible} okText="Log In" onOk={this.doLogin} onCancel={this.doCancel} confirmLoading={this.performingLoginRequest}>
          <div className="login-form" >
            <img
              src={logo}
              alt={this.props.intl.formatMessage({ id: "common.alt.logo" })}
              className="logo"
            />
            <Form layout="vertical" >
              <Form.Item>
            <Input
              id="input_login"
              placeholder={this.props.intl.formatMessage({
                id: "login.placeholder.login"
              })}
              onChange={this.changeLogin}
              value={this.login}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </Form.Item>
              <Form.Item>
            <Input
              id="input_password"
              placeholder={this.props.intl.formatMessage({
                id: "login.placeholder.password"
              })}
              onChange={this.changePassword}
              value={this.password}
              type="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </Form.Item>
              <Form.Item>
            <div className="language-switcher-container">
              <LanguageSwitcher className="language-switcher" />
            </div>
          </Form.Item>
           </Form>
          </div>
        </Modal>
    );
  }
}

export default injectIntl(ModalLogin);