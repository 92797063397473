import * as React from "react";
import {Divider} from "antd";

type Props = { ordnum: number, bkrnum: string, brkId: string, brokerName: string }
type State = { isLoaded: boolean, mpsumpdf : string, mpsumxls: string, compver: string, download : string, status: string }

export class BrokerReportsLink extends React.Component<Props, State> {

  _isMounted = false;

  constructor(props: Props) {
    super(props)
    const compver : string = this.props.brkId + "." + this.props.brokerName + ".compver.pdf"
    const mpsumpdf : string = this.props.brkId + "." + this.props.brokerName + ".mpsum.pdf"
    const mpsumxls : string = this.props.brkId + "." + this.props.brokerName + ".mpsum.xls"
    const download: string = this.props.brkId + ".reports.zip"
    this.state = {
      isLoaded: false,
      mpsumpdf: "https://storage.googleapis.com/website-paslists/mergerpts/" + this.pad(this.props.ordnum, 7) + "/" + encodeURIComponent(mpsumpdf),
      mpsumxls: "https://storage.googleapis.com/website-paslists/mergerpts/" + this.pad(this.props.ordnum, 7) + "/" + encodeURIComponent(mpsumxls),
      compver: "https://storage.googleapis.com/website-paslists/mergerpts/" + this.pad(this.props.ordnum, 7) + "/" + encodeURIComponent(compver),
      download: "https://storage.googleapis.com/website-paslists/mergerpts/" + this.pad(this.props.ordnum, 7) + "/" + encodeURIComponent(download),
      status: ""
    }
  }

  componentDidMount() {
    this._isMounted = true;

    fetch(this.state.download, {method: 'HEAD'})
    .then(res => {
        if (this._isMounted) {
          if (res.ok) {
            this.setState({
              isLoaded: true,
              status: 'url'
            })
          } else {
            this.setState({
              isLoaded: true,
              status: "No reports available"
            })
          }
        }
      },
        error => {
          if (this._isMounted) {
            this.setState({
              isLoaded: true,
              status: "No reports available (error)"
            })
            console.log("fetchReportsLink promise error: " + error)
          }
        }
    );
  }

  UNSAFE_componentWillUnmount() {
    this._isMounted = false;
  }

  pad(num : number, size : number) : string {
    return ('0000000000' + num).substr(-size)
  }

  render() {
    const {isLoaded, status, mpsumpdf, mpsumxls, compver, download} = this.state;
    if (!isLoaded) {
      return null;
    }
    else if (status === 'url') {
      return (
        <span>
          <a target="_blank" rel="noopener noreferrer" href={mpsumpdf}>Reports: MP Summary-PDF</a>&nbsp;
          <Divider type="vertical"/>&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={mpsumxls}>MP Summary-Excel</a>&nbsp;
          <Divider type="vertical"/>&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={compver}>CV</a>&nbsp;
          <Divider type="vertical"/>&nbsp;
          <a target="_blank" rel="noopener noreferrer" href={download}>Download</a></span>
      )
    } else {
      return (<div>{status}</div>)
    }
  }

}
