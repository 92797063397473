import { BaseLongIdEntity } from "./base/sys$BaseLongIdEntity";
import { Orders } from "./pasweb_Orders";
import { Lists } from "./pasweb_Lists";
import { Mailers } from "./pasweb_Mailers";
import { OrderStatusEnum } from "../enums/enums";
export class Ordstatus extends BaseLongIdEntity {
  static NAME = "pasweb_Ordstatus";
  order?: Orders | null;
  lst?: Lists | null;
  list?: string | null;
  mlr?: Mailers | null;
  mailer?: string | null;
  mgrnum?: string | null;
  bkrnum?: string | null;
  orddate?: any | null;
  maildate?: any | null;
  wantdate?: any | null;
  rundate?: any | null;
  shipdate?: any | null;
  status?: OrderStatusEnum | null;
  qtyappdate?: any | null;
  shipvia?: string | null;
  statdesc?: string | null;
  shipqty?: number | null;
  specinst?: string | null;
  holduntil?: any | null;
  statorder?: number | null;
  cancelled?: string | null;
  canceldesc?: string | null;
  pasdecoy?: string | null;
  mgrdecoy?: string | null;
}
export type OrdstatusViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "ordstatus-view";
export type OrdstatusView<V extends OrdstatusViewName> = V extends "_base"
  ? Pick<
      Ordstatus,
      | "id"
      | "list"
      | "mailer"
      | "mgrnum"
      | "bkrnum"
      | "orddate"
      | "maildate"
      | "wantdate"
      | "rundate"
      | "shipdate"
      | "status"
      | "qtyappdate"
      | "shipvia"
      | "statdesc"
      | "shipqty"
      | "specinst"
      | "holduntil"
      | "statorder"
      | "cancelled"
      | "canceldesc"
      | "pasdecoy"
      | "mgrdecoy"
    >
  : V extends "_local"
  ? Pick<
      Ordstatus,
      | "id"
      | "list"
      | "mailer"
      | "mgrnum"
      | "bkrnum"
      | "orddate"
      | "maildate"
      | "wantdate"
      | "rundate"
      | "shipdate"
      | "status"
      | "qtyappdate"
      | "shipvia"
      | "statdesc"
      | "shipqty"
      | "specinst"
      | "holduntil"
      | "statorder"
      | "cancelled"
      | "canceldesc"
      | "pasdecoy"
      | "mgrdecoy"
    >
  : V extends "_minimal"
  ? Pick<Ordstatus, "id">
  : V extends "ordstatus-view"
  ? Pick<
      Ordstatus,
      | "id"
      | "list"
      | "mailer"
      | "mgrnum"
      | "bkrnum"
      | "orddate"
      | "maildate"
      | "wantdate"
      | "rundate"
      | "shipdate"
      | "status"
      | "qtyappdate"
      | "shipvia"
      | "statdesc"
      | "shipqty"
      | "specinst"
      | "holduntil"
      | "statorder"
      | "cancelled"
      | "canceldesc"
      | "pasdecoy"
      | "mgrdecoy"
      | "order"
      | "lst"
      | "mlr"
    >
  : never;
