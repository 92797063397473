import * as React from "react";

import {message, Typography} from "antd";
import { observer } from "mobx-react";
import ModalLogin from "../login/ModalLogin";

import {
  collection, DataCollectionStore,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react-core";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import SecureContent from "./SecureContent";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {action,  observable} from "mobx";
import {RouteComponentProps} from "react-router";

const { Title } = Typography;

type Props = RouteComponentProps & MainStoreInjected & WrappedComponentProps;

@injectMainStore
@observer
class SecureAreaComponent extends React.Component<Props> {

  webUsers: DataCollectionStore<Webusers>;

  @observable performingWebuserLookup = false;
  lookupFailed = false;
  webUser: Webusers;

  @action
  doWebuserLookup(userName: string | undefined) {
    this.performingWebuserLookup = true;
    this.webUsers = collection<Webusers>(Webusers.NAME, {
      view: "webusers-view",
      filter: {
        conditions: [
          {property: "login", operator: "=", value: userName || null}
        ]
      },
      loadImmediately: false
    });
    this.webUsers.load()
      .then(
        action(() => {
          this.webUser = this.webUsers.items[0];
          this.performingWebuserLookup = false;
          this.lookupFailed = false;
        })
      )
      .catch(
        action(() => {
          this.performingWebuserLookup = false;
          message.error(this.props.intl.formatMessage({ id: "secureArea.userNotFound" }));
          this.lookupFailed = true;
        })
      );
  }

  render() {

    if (!this.props.mainStore!.authenticated) {
      return (
        <ModalLogin {...this.props} title="PAS Client Access"/>
      );
    }

    if (!this.webUser) {
      if (this.lookupFailed) {
        return (<Title level={1}>User Account not found</Title>)
      }
      if (!this.performingWebuserLookup) {
        this.doWebuserLookup(this.props.mainStore!.userName)
      }
      return null;
    }

   if (!this.webUser.customer && !this.webUser.salesrep && !this.webUser.broker) {
     return (<Title level={1}>Customer, Broker or SalesRep not assigned for this Web User</Title>);
   }

   return (<SecureContent {...this.props} webUser={this.webUser}/>)

  }

}

const SecureArea = injectIntl(SecureAreaComponent);

export default SecureArea;
