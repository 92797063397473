import * as React from "react";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {observable} from "mobx";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {BrokerMailerSH} from "../../cuba/entities/pasweb_BrokerMailerSH";
import {Button} from "antd";
import {DataTable} from "@cuba-platform/react-ui";
import Moment from "moment";
import {BrokerReportsLink} from "../reportsLink/BrokerReportsLink";
import {observer} from "mobx-react";

type Props = { cusId: string; brkId: string; } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
export class BrokerMailerMergesListComponent extends React.Component<Props> {

  @observable dataCollection: DataCollectionStore<BrokerMailerSH>;
  @observable selectedRowKey: string | undefined;
  @observable ordnum: string | undefined;

  componentDidMount() {

    this.dataCollection = collection<BrokerMailerSH>(BrokerMailerSH.NAME, {
      view: "brokerMailerSH-view",
      sort: "-orddate",
      filter: {
        conditions: [
          {property: "broker", operator: "=", value: this.props.brkId},
          {property: "customer", operator: "=", value: this.props.cusId},
        ]
      },
    });

  }

  render() {

    if (!this.dataCollection) return null;

    const buttons = [
      <Button
        key="edit"
        htmlType="button"
        style={{ margin: "0 12px 12px 0" }}
        disabled={!this.selectedRowKey}
        type="default"
        onClick={() => { this.props.history.push("/mergesView/" + this.ordnum + "/" + this.props.brkId)}}
      >
        <FormattedMessage id="common.view" />
      </Button>
    ];

    return (

      <div>
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={true}
          tableProps={{bordered: true}}
          columnDefinitions={[
            {field: "bkrnum", columnProps: { title: "Bkr Num" }},
            {field: "ordnum", columnProps: {title: "PAS Num" }},
            {field: "orddate", columnProps: { title: "Order Date", render: (text, record: any) => ( Moment(record.orddate).format("MM/DD/YYYY"))}},
            {field: "cutoffdate", columnProps: {title: "Cutoff Date", render: (text, record: any) => ( Moment(record.cutoffdate).format("MM/DD/YYYY"))}},
            {field: "wantdate", columnProps: {title: "Wanted By", render: (text, record: any) => ( Moment(record.wantdate).format("MM/DD/YYYY"))}},
            {field: "maildate", columnProps: {title: "Mail Date", render: (text, record: any) => ( Moment(record.maildate).format("MM/DD/YYYY"))}},
            {field: "shipdate", columnProps: {title: "Ship Date", render: (text, record: any) => ( Moment(record.shipdate).format("MM/DD/YYYY"))}},
            {field: "totQtyOrdered", columnProps: {title: "Qty Ordered", render: (text, record:any) => ( record.totQtyOrdered == null? 0 : record.totQtyOrdered.toLocaleString('en-US', {maximumFractionDigits:0}))}},
            {field: "totQtyReceived", columnProps: {title: "Qty Received", render: (text, record:any) => ( record.totQtyReceived == null? 0: record.totQtyReceived.toLocaleString('en-US', {maximumFractionDigits:0}))}},
            {field: "totQtyConverted", columnProps: {title: "Qty Converted", render: (text, record:any) => ( record.totQtyConverted == null? 0: record.totQtyConverted.toLocaleString('en-US', {maximumFractionDigits:0}))}},
            {columnProps: {
                render: (text, record : any) => (
                  <BrokerReportsLink key={record.id} ordnum={record.order.id} bkrnum={record.bkrnum} brkId={record.broker.id} brokerName={record.brokername}/>
                )
              }
            }
          ]}
          onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
          hideSelectionColumn={true}
          buttons={buttons}
        />
      </div>
    );
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    const selKey = selectedRowKeys[0];
    this.selectedRowKey = selKey;
    const item : BrokerMailerSH | undefined = this.dataCollection.items.find(value => value.id === selKey)
    if (item) {
      this.ordnum = item.order?.id;
    }
  };
}

const BrokerMailerMergesList = injectIntl(BrokerMailerMergesListComponent);

export default BrokerMailerMergesList;