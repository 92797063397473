import * as React from "react";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import {WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {observable} from "mobx";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {Mplists} from "../../cuba/entities/pasweb_Mplists";
import {DataTable} from "@cuba-platform/react-ui";
import Moment from "moment";
import {observer} from "mobx-react";
import {Button} from "antd";

type Props = { webUser: Webusers } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
export class MailerMergeSearchForm extends React.Component<Props> {

  @observable dataCollection: DataCollectionStore<Mplists>;
  @observable searchKey: string = "";
  @observable searchPO: string = "";
  @observable loading: boolean = false;

  onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    if (ev.target.name === "searchKey") this.searchKey = ev.target.value
    if (ev.target.name === "searchPO") this.searchPO = ev.target.value
  }

  onReset = (e: React.FormEvent) => {
    e.preventDefault()
    this.searchKey = ""
    this.searchPO = ""
    this.dataCollection?.clear()
    this.loading = false
  }

  mailerSearch = (e : React.FormEvent ) => {

    const cusId = this.props.webUser.customer?.id;

    e.preventDefault();

    if (!cusId || (this.searchKey === "" && this.searchPO === "")) {
      this.dataCollection?.clear()
      this.loading = false
    } else if (this.searchKey !== "" && this.searchPO !== "") {
      this.loading = false
    } else if (this.searchKey !== "") {
      this.loading = true
      this.dataCollection = collection<Mplists>(Mplists.NAME, {
        view: 'mplists-view',
        filter: {
          conditions: [
            {property: "keycode", operator: 'contains', value: this.searchKey},
            {property: "ordOrdnum.cus", operator: "=", value: cusId}
          ]
        },
        loadImmediately: false
      })
      this.dataCollection.load().then(value => {
        this.loading = false
      })
    } else if (this.searchPO !== "") {
      this.loading = true
      this.dataCollection = collection<Mplists>(Mplists.NAME, {
        view: 'mplists-view',
        filter: {
          conditions: [
            {property: "brokerpo", operator: 'contains', value: this.searchPO},
            {property: "ordOrdnum.cus", operator: "=", value: cusId}
          ]
        },
        loadImmediately: false
      })
      this.dataCollection.load().then(value => {
        this.loading = false
      })
    }
  }

  render() {

    return (
        <div>
          <form id="MailerSearchForm" onSubmit={this.mailerSearch.bind(this)} onReset={this.onReset.bind(this)}>
            Keycode:
            <input name = "searchKey" id="searchKey" type="text" value={this.searchKey} onChange={this.onChange.bind(this)}/>
            OR Broker PO:
            <input name = "searchPO" id="searchPO" type="text" value={this.searchPO} onChange={this.onChange.bind(this)}/>
            <Button htmlType="submit" type="primary" loading={this.loading}>Search</Button>
            <Button htmlType="reset">Clear</Button>
          </form>
          { (this.searchKey !== '' && this.searchPO !=='') ? <div><br/><h2>Please specify one of Keycode or BrokerPO</h2></div> : null }
          <br/>
          {(this.dataCollection?.status === 'DONE') && (this.dataCollection?.count === 0) ?
            <div><h2>No lists found matching the requested criteria</h2></div>
            : null
          }
          {this.dataCollection && this.dataCollection.status === "DONE" && this.dataCollection.count && this.dataCollection.count > 0 ?
            <DataTable
              dataCollection={this.dataCollection}
              canSelectRowByClick={false}
              tableProps={{bordered: true}}
              columnDefinitions={[
                {field: "ordOrdnum", columnProps: {title: "PAS Num", render: (text, record:any) => record.ordOrdnum.id}},
                {field: "keycode", columnProps: {title: "Keycode"}},
                {field: "list", columnProps: {title: "List"}},
                {field: "segment", columnProps: {title: "Segment"}},
                {
                  field: "ordqty",
                  columnProps: {
                    title: "Order Qty",
                    render: (text, record: any) => (record.ordqty == null ? 0 : record.ordqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
                  }
                },
                {
                  field: "inputqty",
                  columnProps: {
                    title: "Received Qty",
                    render: (text, record: any) => (record.inputqty == null ? 0 : record.inputqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
                  }
                },
                {
                  field: "actqty",
                  columnProps: {
                    title: "Converted Qty",
                    render: (text, record: any) => (record.actqty == null ? 0 : record.actqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
                  }
                },
                {
                  field: "daterecd",
                  columnProps: {
                    title: "Date Recd",
                    render: (text, record: any) => (record.daterecd == null ? "" : Moment(record.daterecd).format("MM/DD/YYYY"))
                  }
                },
                {field: "statusDesc", columnProps: {title: "Status"}},
                {field: "brk", columnProps: {title: "Broker", render: (text, record: any) => (record.brk.company)}},
                {field: "brokerpo", columnProps: {title: "Broker PO"}}
              ]}
              hideSelectionColumn={true}
            />
            : null
          }
      </div>
    )
  }
}
