import { BaseLongIdEntity } from "./base/sys$BaseLongIdEntity";
import { Customers } from "./pasweb_Customers";
export class Lists extends BaseLongIdEntity {
  static NAME = "pasweb_Lists";
  arclim?: any | null;
  arctab?: string | null;
  cntmonths?: any | null;
  countrycd?: string | null;
  countsemail?: string | null;
  countsemailtemplate?: string | null;
  cus?: Customers | null;
  diskchg?: any | null;
  diskmin?: any | null;
  diskunit?: string | null;
  emailchg?: any | null;
  hotlinefmt?: string | null;
  keychg?: any | null;
  keylen?: any | null;
  keypos?: any | null;
  lastupd?: any | null;
  lstTable?: string | null;
  maxchg?: any | null;
  maxhldate?: any | null;
  mgr?: Customers | null;
  minchg?: any | null;
  minselflag?: string | null;
  noRentals?: boolean | null;
  nocounts?: boolean | null;
  own?: Customers | null;
  pschg?: any | null;
  psmin?: any | null;
  qtyappemail?: string | null;
  runchg?: any | null;
  rununit?: string | null;
  seedsperorder?: any | null;
  selchg?: any | null;
  selstmt?: string | null;
  selunit?: string | null;
  sendmonthlycnts?: boolean | null;
  shipemail?: string | null;
  specinst?: string | null;
  specinstc?: string | null;
  tapechg?: any | null;
  title?: string | null;
  updchg?: any | null;
  updlim?: any | null;
  updlistid?: any | null;
  updmin?: any | null;
  updnotifydate?: any | null;
  updproc?: string | null;
  updunit?: string | null;
  zipdchg?: any | null;
  zipdmin?: any | null;
  zipdunit?: string | null;
}
export type ListsViewName = "_base" | "_local" | "_minimal";
export type ListsView<V extends ListsViewName> = V extends "_base"
  ? Pick<
      Lists,
      | "id"
      | "title"
      | "arclim"
      | "arctab"
      | "cntmonths"
      | "countrycd"
      | "countsemail"
      | "countsemailtemplate"
      | "diskchg"
      | "diskmin"
      | "diskunit"
      | "emailchg"
      | "hotlinefmt"
      | "keychg"
      | "keylen"
      | "keypos"
      | "lastupd"
      | "lstTable"
      | "maxchg"
      | "maxhldate"
      | "minchg"
      | "minselflag"
      | "noRentals"
      | "nocounts"
      | "pschg"
      | "psmin"
      | "qtyappemail"
      | "runchg"
      | "rununit"
      | "seedsperorder"
      | "selchg"
      | "selstmt"
      | "selunit"
      | "sendmonthlycnts"
      | "shipemail"
      | "specinst"
      | "specinstc"
      | "tapechg"
      | "updchg"
      | "updlim"
      | "updlistid"
      | "updmin"
      | "updnotifydate"
      | "updproc"
      | "updunit"
      | "zipdchg"
      | "zipdmin"
      | "zipdunit"
    >
  : V extends "_local"
  ? Pick<
      Lists,
      | "id"
      | "arclim"
      | "arctab"
      | "cntmonths"
      | "countrycd"
      | "countsemail"
      | "countsemailtemplate"
      | "diskchg"
      | "diskmin"
      | "diskunit"
      | "emailchg"
      | "hotlinefmt"
      | "keychg"
      | "keylen"
      | "keypos"
      | "lastupd"
      | "lstTable"
      | "maxchg"
      | "maxhldate"
      | "minchg"
      | "minselflag"
      | "noRentals"
      | "nocounts"
      | "pschg"
      | "psmin"
      | "qtyappemail"
      | "runchg"
      | "rununit"
      | "seedsperorder"
      | "selchg"
      | "selstmt"
      | "selunit"
      | "sendmonthlycnts"
      | "shipemail"
      | "specinst"
      | "specinstc"
      | "tapechg"
      | "title"
      | "updchg"
      | "updlim"
      | "updlistid"
      | "updmin"
      | "updnotifydate"
      | "updproc"
      | "updunit"
      | "zipdchg"
      | "zipdmin"
      | "zipdunit"
    >
  : V extends "_minimal"
  ? Pick<Lists, "id" | "title">
  : never;
