import { CubaApp, FetchOptions } from "@cuba-platform/rest";

export type pasweb_ContactEmailService_sendEmail_params = {
  name: any;
  email: any;
  message: any;
};

export var restServices = {
  pasweb_ContactEmailService: {
    sendEmail: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: pasweb_ContactEmailService_sendEmail_params
    ) => {
      return cubaApp.invokeService(
        "pasweb_ContactEmailService",
        "sendEmail",
        params,
        fetchOpts
      );
    }
  }
};
