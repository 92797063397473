import * as React from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { CSVLink } from "react-csv";

import { observable } from "mobx";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  MainStoreInjected,
  injectMainStore, collection
} from "@cuba-platform/react-core";

import "../../app/App.css";

import {Mplists} from "../../cuba/entities/pasweb_Mplists";
import Moment from "moment";
import {DataTable} from "@cuba-platform/react-ui";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";

type Props =  RouteComponentProps<ViewerProps> & MainStoreInjected & WrappedComponentProps;

type ViewerProps = {
  entityId: string;
  brkId?: string;
};

@injectMainStore
@observer
class MergesViewComponent extends React.Component<Props> {

  @observable dataCollection : DataCollectionStore<Mplists>;

  componentDidMount() {
    if (this.props.match.params.brkId) {
      this.dataCollection = collection<Mplists>(Mplists.NAME, {
        view: "mplists-view",
        sort: "keycode",
        filter: {conditions: [
            {property: "ordOrdnum", operator: "=", value: this.props.match.params.entityId},
            {property: "brk", operator: "=", value: this.props.match.params.brkId}
          ]}
      });
    } else {
      this.dataCollection = collection<Mplists>(Mplists.NAME, {
        view: "mplists-view",
        sort: "keycode",
        filter: {
          conditions: [
            {property: "ordOrdnum", operator: "=", value: this.props.match.params.entityId}
          ]
        }
      });
    }
  }

  render() {

    if (!this.dataCollection) return null;

    const buttons = [
         <CSVLink key="csv"
                  className="ant-btn ant-btn-primary"
                  data={this.dataCollection.items.map( item => {
                     return {
                       bkrnum:   item.ordOrdnum?.bkrnum,
                       keycode:  item.keycode,
                       list:     item.list,
                       segment:  item.segment,
                       broker:   item.brk?.company,
                       brokerpo: item.brokerpo,
                       orderqty: item.ordqty,
                       recdqty:  item.recdqty,
                       convqty:  item.actqty,
                       daterecd: item.daterecd?.substring(0,10),
                       status:   item.statusDesc
                   }
                  })}
                  headers={[
                    { label: "Client PO", key: "bkrnum" },
                    { label: "Keycode", key: "keycode"},
                    { label: "List", key: "list" },
                    { label: "Segment", key: "segment"},
                    { label: "Broker", key: "broker"},
                    { label: "Broker PO", key: "brokerpo"},
                    { label: "Order Qty", key: "orderqty"},
                    { label: "Received Qty", key: "recdqty"},
                    { label: "Converted Qty", key: "convqty" },
                    { label: "Date Received", key: "daterecd" },
                    { label: "Status", key: "status"}
                  ]}
                  filename={"merge_" + this.props.match.params.entityId + "_export.xlsx"}
         >
          Excel
        </CSVLink>
    ];

    return (
      <Modal width="90vw"
             destroyOnClose={true}
             centered={true}
             cancelButtonProps={{ disabled: true}}
             okText="Back"
             onOk={() => { this.props.history.go(-1) }}
             closable={false}
             visible={true}
             title={"PAS Merge Job #: " + this.props.match.params.entityId}
      >
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={false}
          tableProps={{ bordered: true }}
          columnDefinitions={[
            {field: "keycode", columnProps: { title: "Keycode" }},
            {field: "list", columnProps: {title: "List"}},
            {field: "segment", columnProps: {title: "Segment"}},
            {field: "ordqty", columnProps: {title: "Order Qty", render: (text, record:any) => ( record.ordqty == null? 0 : record.ordqty.toLocaleString('en-US', {maximumFractionDigits:0}))}},
            {field: "inputqty", columnProps: {title: "Received Qty", render: (text, record:any) => ( record.inputqty == null? 0 : record.inputqty.toLocaleString('en-US', {maximumFractionDigits:0}))}},
            {field: "actqty", columnProps: {title: "Converted Qty", render: (text, record:any) => ( record.actqty == null? 0 : record.actqty.toLocaleString('en-US', {maximumFractionDigits:0}))}},
            {field: "daterecd", columnProps: { title: "Date Recd", render: (text, record: any) => ( record.daterecd == null? "": Moment(record.daterecd).format("MM/DD/YYYY"))}},
            {field: "statusDesc", columnProps: {title: "Status"}},
            {field: "brk", columnProps: {title: "Broker", render: (text, record: any) => ( record.brk.company )}},
            {field: "brokerpo", columnProps: {title: "Broker PO"}}
          ]}
          hideSelectionColumn={true}
          buttons={buttons}
        />
      </Modal>
    );
  }

}

const MergesView = injectIntl(MergesViewComponent);
export default MergesView;
