import { Button, Icon, Menu, Modal } from "antd";
import * as React from "react";
import "./AppHeader.css";
import logo from "./logo.png";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react-core";
import { LanguageSwitcher } from "../../i18n/LanguageSwitcher";
import { FormattedMessage, injectIntl, IntlFormatters, WrappedComponentProps } from "react-intl";
import { Route, Switch, Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { menuItems } from "../../routing";
import { RouteItem, SubMenu } from "@cuba-platform/react-core";
import { observer } from "mobx-react";

const menuIdx = 1;

@injectMainStore
@observer
class AppHeader extends React.Component<MainStoreInjected & WrappedComponentProps & RouteComponentProps> {

  unloadListener = (ev : Event) => {
      ev.preventDefault();
      if (this.props.mainStore!.authenticated) this.props.mainStore!.logout();
    }

  componentDidMount() {
    window.addEventListener("beforeunload", ev => this.unloadListener(ev));
  }

/*  componentWillUnmount() {
    window.removeEventListener("beforeunload", ev => this.unloadListener(ev));
  }*/

  render() {

    return (
      <div id="home" className="app-header">
        <div>
          <img
            style={{ paddingLeft: "10px"}}
            src={logo}
            alt={this.props.intl.formatMessage({ id: "common.alt.logo" })}
            onClick={() => {
              this.props.history.push("/pasweb-front/#home");
            }}
          />
        </div>
        <div className="corpHeader">
          <div className="corpName">Professional Advertising Systems Inc.</div>
          <div className="corpAddr">3 Morgan Drive #310, Mt. Kisco, NY 10549</div>
          <div className="corpPhone">914-765-0500&nbsp;&nbsp;&nbsp;info@paslists.com</div>
        </div>
        <div className="rightMenu">
          <Menu mode="horizontal" >
            {menuItems.map((item, idx) =>
              menuItem(item, "" + (idx + 1 + menuIdx), this.props.intl)
            )}
          </Menu>
          <Switch>
            {collectRouteItems(menuItems).map(route => (
               <Route exact={true} key={route.pathPattern} path={route.pathPattern} component={route.component} />
            ))}
          </Switch>
        </div>
        <div className="user-panel">
          <LanguageSwitcher className="panelelement language-switcher -header" />
          <span className="panelelement">{this.props.mainStore!.userName}</span>
          <Button
            className="panelelement"
            disabled={ this.props.mainStore!.userName == null }
            id="button_logout"
            //ghost={true}
            icon="logout"
            onClick={this.showLogoutConfirm}
          />
        </div>
      </div>
    );
  }

  showLogoutConfirm = () => {
    Modal.confirm({
      title: this.props.intl.formatMessage({ id: "header.logout.areYouSure" }),
      okText: this.props.intl.formatMessage({ id: "header.logout.ok" }),
      cancelText: this.props.intl.formatMessage({ id: "header.logout.cancel" }),
      onOk: () => {
        this.props.mainStore!.logout()
          .then(() => {
            this.props.mainStore!.userName = undefined;
            this.props.history.push("/pasweb-front/#home");
          });
      }
    });
  };
}

function menuItem(
/*  item: PASRouteItem | PASSubMenu,*/
  item: RouteItem | SubMenu,
  keyString: string,
  intl: IntlFormatters
) {
  // Sub Menu

  if ((item as any).items != null) {
    //recursively walk through sub menus
    return (
      <Menu.SubMenu
        key={keyString}
        title={intl.formatMessage({
          id: "router." + item.caption
        })}
      >
        {(item as SubMenu).items.map((ri, index) =>
          menuItem(ri, keyString + "-" + (index + 1), intl)
        )}
      </Menu.SubMenu>
    );
  }

  // Route Item

  const { menuLink } = item as RouteItem;

  if (menuLink !== "") {
    return (
      <Menu.Item key={keyString}>
        <Link to={menuLink}>
          <Icon type="bars"/>
          <FormattedMessage id={"router." + item.caption}/>
        </Link>
      </Menu.Item>
    )
  } else {
    return null;
  }
}

function collectRouteItems(items: Array<RouteItem | SubMenu>): RouteItem[] {
  return items.reduce((acc, curr) => {
    if ((curr as SubMenu).items == null) {
      // Route item
      acc.push(curr as RouteItem);
    } else {
      // Items from sub menu
      acc.push(...collectRouteItems((curr as SubMenu).items));
    }
    return acc;
  }, [] as Array<RouteItem>);
}

export default injectIntl(AppHeader);
