import * as React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import {Button} from "antd";
import Moment from 'moment';

import {
  collection,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react-core";
import { DataTable } from "@cuba-platform/react-ui";

import {
  FormattedMessage, injectIntl,
  WrappedComponentProps
} from "react-intl";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {MailerReportsLink} from "../reportsLink/MailerReportsLink";
import {RouteComponentProps} from "react-router";
import {MPStatus} from "../../cuba/entities/pasweb_MPStatus";

type Props = { cusId: string } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
class MergesListComponent extends React.Component<Props>
{
  @observable dataCollection: DataCollectionStore<MPStatus>;
  @observable selectedRowKey: string | undefined;

  componentDidMount() {
    const customerId = this.props.cusId;
    if (customerId) {
      this.dataCollection = collection<MPStatus>(MPStatus.NAME, {
        view: "mpstatus-view",
        sort: "-orddate",
        filter: {
          conditions: [
            {property: "order.cancelled", operator: "=", value: "1"},
            {property: "customer", operator: "=", value: customerId}
          ]
        },
      });
    }
  }

  render() {

    if (!this.dataCollection) return null;

    const buttons = [
        <Button
          key="edit"
          htmlType="button"
          style={{ margin: "0 12px 12px 0" }}
          disabled={!this.selectedRowKey}
          type="default"
          onClick={() => { this.props.history.push("/mergesView/" + this.selectedRowKey)}}
        >
          <FormattedMessage id="common.view" />
        </Button>
    ];

    return (

      <DataTable
        dataCollection={this.dataCollection}
        canSelectRowByClick={true}
        tableProps={{bordered: true, }}
        columnDefinitions={[
          {field: "bkrnum", columnProps: { title: "Bkr Num" }},
          {field: "id", columnProps: {title: "PAS Num" }},
          {field: "orddate", columnProps: { title: "Order Date", render: (text, record: any) => ( record.orddate? Moment(record.orddate).format("MM/DD/YYYY") : null)}},
          {field: "cutoffdate", columnProps: {title: "Cutoff Date", render: (text, record: any) => ( record.cutoffdate? Moment(record.cutoffdate).format("MM/DD/YYYY") : null)}},
          {field: "wantdate", columnProps: {title: "Wanted By", render: (text, record: any) => ( record.wantdate? Moment(record.wantdate).format("MM/DD/YYYY") : null)}},
          {field: "maildate", columnProps: {title: "Mail Date", render: (text, record: any) => ( record.maildate? Moment(record.maildate).format("MM/DD/YYYY") : null)}},
          {field: "shipdate", columnProps: {title: "Ship Date", render: (text, record: any) => ( record.shipdate? Moment(record.shipdate).format("MM/DD/YYYY") : null)}},
          {field: "totQtyOrdered", columnProps: {title: "Qty Ordered", render: (text, record:any) => ( record.totQtyOrdered == null? 0 : record.totQtyOrdered.toLocaleString('en-US', {maximumFractionDigits:0}))}},
          {field: "totQtyReceived", columnProps: {title: "Qty Received", render: (text, record:any) => ( record.totQtyReceived == null? 0: record.totQtyReceived.toLocaleString('en-US', {maximumFractionDigits:0}))}},
          {field: "totQtyConverted", columnProps: {title: "Qty Converted", render: (text, record:any) => ( record.totQtyConverted == null? 0: record.totQtyConverted.toLocaleString('en-US', {maximumFractionDigits:0}))}},
          {columnProps: {
            render: (text, record : any) => (
                <MailerReportsLink key={record.id} ordnum={record.id} bkrnum={record.bkrnum}/>
              )
            }
          }
        ]}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const ConsultantMailerMergesList = injectIntl(MergesListComponent);

export default ConsultantMailerMergesList;
