import * as React from "react";
import "./App.css";

import { Layout } from "antd";
import { observer } from "mobx-react";
import AppHeader from "./header/AppHeader";
import {
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react-core";
import { CenteredLoader } from "./CenteredLoader";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import HomePage from "./home/HomePage";
import HashLinkObserver from "react-hash-link";
import {RouteComponentProps} from "react-router";

@injectMainStore

@observer
class AppComponent extends React.Component<
  RouteComponentProps & MainStoreInjected & WrappedComponentProps
> {
  render() {
    const mainStore = this.props.mainStore!;
    const { initialized, locale } = mainStore;

    if (!initialized || !locale) {
      return <CenteredLoader />;
    }

    return (
      <Layout className="main-layout">
        <HashLinkObserver/>
        <Layout.Header>
          <AppHeader {...this.props}/>
        </Layout.Header>
        <Layout className="layout-container">
          <Layout className="layout-content">
            <Layout.Content>
              <HomePage {...this.props}/>
            </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

const App = injectIntl(AppComponent);
export default App;
