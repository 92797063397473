import * as React from "react";
import './HomePage.css';
import {Row, Col, Affix, Collapse, Typography, BackTop, Divider} from "antd";
import {ContactForm} from "../contactForm/ContactForm";
import merge_icon from "./merge.png";
import cleaning_icon from "./cleaning.png";
import imb_icon from "./imb.png";
import rental_icon from "./rental.png";
import dbmaint_icon from "./dbmaint.png";
import rade_icon from "./rade.png";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import {observer} from "mobx-react";
import SecureArea from "../secureArea/SecureArea";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import {RouteComponentProps} from "react-router";

const { Panel } = Collapse;
const { Text, Title } = Typography;

type Props = RouteComponentProps & MainStoreInjected & WrappedComponentProps;

@injectMainStore
@observer
class HomePage extends React.Component<Props> {

  render() {

    if (!this.props.mainStore!.loginRequired && this.props.mainStore!.userName) {
      return (
        <div>
          <BackTop style={{position: 'fixed', bottom: 0, right: 20}}/>
          <SecureArea {...this.props}/>
        </div>
      )
    } else {
      return (
        <div>
          <BackTop style={{position: 'fixed', bottom: 0, right: 20}}/>
          <div className="background">
            <Row justify="space-around" align="middle">
              <Col span={24} className="fgText">
                <div>
                  <strong className="fgHeader">Mail <i>Smarter</i></strong>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Divider/>
            <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
              <Col span={6}/>
              <Col span={14}>
                <Text className="fgText">
                  We are Professional Advertising Systems Inc., specializing in everything you need to turn lists of names and addresses
                  into mailable files.  Incorporated in 2000,
                  we've processed thousands of jobs for hundreds of mailers - quickly, accurately and cost-effectively.
                  <br/><br/>
                  <p style={{textAlign: "center", fontSize: "x-large"}}>Contact us today if you want to Mail <i>Smarter</i>.</p>
                </Text>
              </Col>
            </Row>
          </div>
          <div>
            <Divider/>
            <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
              <Col span={2}/>
              <Col span={2}>
                <div id="merge">
                  <img src={merge_icon} alt="Merge/Purge"/>
                </div>
              </Col>
              <Col span={4}>
                <Title level={4}>Merge/Purge Services</Title>
              </Col>
              <Col span={12}>
                <Text className="fgText">
                  Our Merge/Purge systems are based on SAP Data Services (originally Postalsoft).
                  However, in order to provide a better quality product, we've customized this software
                  to better suit the needs of the Direct Marketing community. We've also developed a
                  custom set of reports that are second to none, detailing everything you might want
                  to know about your mail preparation. Included are some of the best Computer Verification
                  reports in the industry, making it easier to substantiate mailing list deductions and
                  save money.
                </Text>
                <br/><br/>
                <Text className="fgText">
                  No matter where in the world you want to mail, we have a solution that fits your needs.
                </Text>
              </Col>
            </Row>
            <Divider/>
            <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
              <Col span={2}/>
              <Col span={2}>
                <div id="clean">
                  <img src={cleaning_icon} alt="Address Cleansing"/>
                </div>
              </Col>
              <Col span={4}>
                <Title level={4}>Address Cleansing Services</Title>
              </Col>
              <Col span={12}>
                <Text className="fgText">
                  We have a number of services available for Address Cleansing.
                  Depending on the requirements, we will ensure that your data is always accurate and mailable.
                </Text>
                <br/><br/>
                <Collapse>
                  <Panel key="ncoa" header="NCOA - US National Change of Address">
                    <Text className="fgText">
                      The US National Change of Address (NCOA) system is a database maintained by the United States
                      Postal Service (USPS)
                      of all people who have moved in the past 48 months. We can pass any of your data files against
                      this massive database,
                      updating the addresses to enhance deliverability. It is also required if you wish to take
                      advantage of US presorted
                      postage rates, since any presorted mail must have been NCOA'd within 95 days of mail
                      date.<br/><br/>
                      This is the most cost-effective method available for updating your files with new addressing
                      information as well as limiting the amount of undeliverable mail you send out, since Standard Mail
                      is not forwarded by the USPS. Maximizing your deliverable mail will lower your cost per piece
                      mailed, which in turn maximizes your net profit.
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel key="cncoa" header="Canadian NCOA - Canadian National Change of Address">
                    <Text className="fgText">
                      Each year, approximately 1.2 million households file a change of address notification with Canada
                      Post.
                      The Canada Post Change of Address (NCOA) system contains over 10 million moves covering the last 6
                      years of
                      address change information.<br/><br/>
                      Updating your data via Canadian NCOA ensures that your mail gets to the people you intend to
                      reach,
                      even if they have moved. Reaching the correct target at the right time is the best way to maximize
                      response to your mailing.
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel key="cass"
                         header="CASS/DPV - US Address Standardization and Correction/Delivery Point Validation">
                    <Text className="fgText">
                      The United States Postal Service (USPS) publishes a database of all active addresses that they can
                      deliver to,
                      as well as standards that reflect exactly how addresses should be written. The Coding Accuracy
                      Support System (CASS)
                      software reads your addresses and updates them to match these standards, correcting them where it
                      can. The CASS systems
                      we use are certified by the USPS in order to guarantee their accuracy, and their databases are
                      updated every 30 days.
                      This insures that we have the most up-to-date address information possible and that we can do the
                      best possible job of
                      cleaning your address data.<br/><br/>
                      Where CASS checks to see that a house number is in the appropriate range on a street, DPV
                      (Delivery Point Validation)
                      goes a step farther. It matches against an even larger database of every deliverable address in
                      the United States.
                      Our systems compare your NCOA'd and CASS'd addresses against this database and remove any record
                      that can't be
                      delivered for any reason, such as an empty lot or the building is known to be vacant.
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel key="serp" header="SERP - Canadian Address Standardization and Correction">
                    <Text className="fgText">
                      Canada Post, via their Address Accuracy initiative, requires that the addresses printed on
                      mailing materials of 5,000 pieces or more must be at least 95% accurate. If the accuracy falls
                      below 95%, they charge an additional $0.05 CAD per mailing piece under the 95% level. For example,
                      if a mailing is only 90% accurate, Canada Post will charge a surcharge on the 5% (95% - 90%) of
                      the mailing.<br/><br/>
                      Their Software Evaluation and Recognition Program (SERP) certifies which software can be used to
                      enforce
                      these standards and produce the appropriate Statement of Accuracy. We use the SERP-certified
                      StreetSweeper
                      system by Pitney Bowes to correct addresses and produce the Statement of Accuracy, ensuring that
                      your mail
                      is delivered quickly and accurately without any surcharges.
                    </Text>
                  </Panel>
                </Collapse>
                <Collapse>
                  <Panel key="gac" header="GAC - Global Address Correction">
                    <Text className="fgText">
                      International Addressing presents unique challenges. Most every country has different formatting
                      requirements and addressing standards. Germany, for example, places the house number after the
                      street name. Japan formats the address with the postal code first, then prefecture (region),
                      city/town/area,
                      then neighborhood and house number in a 3 number sequence (chome-banchi-go).<br/><br/>
                      We have partnered with Global-Z to handle these details, as they have some of the best
                      capabilities
                      and postal databases available. Our on-line feed with them ensures the fastest possible turnaround
                      for your data.
                    </Text>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </div>
          <Divider/>
          <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
            <Col span={2}/>
            <Col span={2}>
              <div id="imb">
                <img src={imb_icon} alt="Mail Tracking"/>
              </div>
            </Col>
            <Col span={4}>
              <Title level={4}>Mail Tracking Services</Title>
            </Col>
            <Col span={12}>
              <Text className="fgText">
                How long did it take for your mail to reach the mailboxes? DID it reach them? Our Mail Tracking
                solution,
                in partnership with SourceLink, shows you where your mail actually is. It's all driven off a special
                barcode
                that we provide in our data when requested. Your printer puts that barcode on the envelope and equipment
                at
                the USPS scans them at various stages in the mail delivery process.<br/><br/>
                Never wonder where your mail is again!
              </Text>
            </Col>
          </Row>
          <Divider/>
          <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
            <Col span={2}/>
            <Col span={2}>
              <div id="rentals">
                <img src={rental_icon} alt="List Rental"/>
              </div>
            </Col>
            <Col span={4}>
              <Title level={4}>List Rental Fulfillment Services</Title>
            </Col>
            <Col span={12}>
              <Text className="fgText">
                Our List Rental Fulfillment systems utilize our Merge/Purge systems for superior
                unduplication of your rental file. We maintain your file in an PostgreSQL database,
                giving you complete selection processing capabilities, ultra-fast counts, and the ultimate
                in data integrity and security. Our turnaround times on orders are ultra-fast as well,
                where almost all orders go out within a few hours of receipt. <br/><br/>
                Rush orders are no problem at PAS!<br/><br/>
                Communication with your List Manager is just as simple. Our automated systems send reports
                via E-Mail showing every order shipped each day, with all the details your manager needs
                for billing. Billing for List Rental Fulfillment is done monthly, with an invoice detailing
                all orders sent out, the selection requested, the quantity shipped, and the charges.
                These bills can be sent directly to you or to your List Manager.<br/><br/>
                Your count reports are available on our secure web site. Just log in to view them.
              </Text>
            </Col>
          </Row>
          <Divider/>
          <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
            <Col span={2}/>
            <Col span={2}>
              <div id="dbmaint">
                <img src={dbmaint_icon} alt="Database Maintenance"/>
              </div>
            </Col>
            <Col span={4}>
              <Title level={4}>Custom Database Services</Title>
            </Col>
            <Col span={12}>
              <Text className="fgText">
                PAS is expert at creating customized marketing/transaction databases for the Direct Marketing
                community. Using the PostgreSQL database system and the latest tools, we can design and implement
                anything you can imagine. You'll be able to access your data and reports any time with secure
                access via the Internet, using exactly the same proven technology we've used to implement our
                on-line Donor Maintenance systems. Since these projects are 'custom' by definition, the pricing
                depends on your requirements and transactional volume. Contact us for more information.
              </Text>
            </Col>
          </Row>
          <Divider/>
          <Row justify={"space-around"} gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
            <Col span={2}/>
            <Col span={2}>
              <div id="rade">
                <img src={rade_icon} alt="Response Analysis"/>
              </div>
            </Col>
            <Col span={4}>
              <Title level={4}>RADE Response Analysis System</Title>
            </Col>
            <Col span={12}>
              <Text className="fgText">
                We have developed a fully integrated Response Analysis system that gives you the ability to confirm that
                your mailings are making money.
                With nightly updates from your fulfillment centers, you have access to your most recent customers and
                accurate results right through to
                Net Profit/Loss and ROI. From mailings to fulfillment costs to auto-shipments, we've got you
                covered.<br/><br/>
                Ask for a demo today!
              </Text>
            </Col>
          </Row>
          <Divider/>
          <Affix style={{position: 'fixed', bottom: 0, right: 100}}>
            <Collapse>
              <Panel key="contactPanel" header="Contact Us" className="contact">
                <ContactForm/>
              </Panel>
            </Collapse>
          </Affix>
        </div>
      )
    }
  }

}

export default injectIntl(HomePage);
