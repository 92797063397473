import * as React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";
import {Button} from "antd";
import Moment from 'moment';

import {
  collection,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react-core";
import { DataTable } from "@cuba-platform/react-ui";

import {
  FormattedMessage, injectIntl,
  WrappedComponentProps
} from "react-intl";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {MailerReportsLink} from "../reportsLink/MailerReportsLink";
import {RouteComponentProps} from "react-router";
import {MPStatus} from "../../cuba/entities/pasweb_MPStatus";
import {MailerMergeSearchForm} from "./MailerMergeSearchForm";

type Props = { webUser: Webusers } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
class MergesListComponent extends React.Component<Props> {
  @observable dataCollection: DataCollectionStore<MPStatus>;
  @observable selectedRowKey: string | undefined;

  componentDidMount() {
    const customerId = this.props.webUser.customer?.id;
    if (customerId) {
      this.dataCollection = collection<MPStatus>(MPStatus.NAME, {
        view: "mpstatus-view",
        sort: "-orddate",
        filter: {
          conditions: [
            {property: "order.cancelled", operator: "=", value: "1"},
            {property: "customer", operator: "=", value: customerId}
          ]
        },
      });
    }
  }


  render() {

    if (!this.dataCollection)  return null;

    const buttons = [
      <Button
        key="edit"
        htmlType="button"
        style={{margin: "0 12px 12px 0"}}
        disabled={!this.selectedRowKey}
        type="default"
        onClick={() => {
          this.props.history.push("/mergesView/" + this.selectedRowKey)
        }}
      >
        <FormattedMessage id="common.view"/>
      </Button>
    ];

    return (

      <div>
        <MailerMergeSearchForm {...this.props}/>
        <DataTable
          dataCollection={this.dataCollection}
          canSelectRowByClick={true}
          enableFiltersOnColumns={['bkrnum','id','orddate']}
          tableProps={{bordered: true, pagination:{pageSize:1000}}}
          columnDefinitions={[
            {field: "bkrnum", columnProps: {title: "Bkr Num"}},
            {field: "id", columnProps: {title: "PAS Num"}},
            {field: "orddate",
              columnProps: {
                title: "Order Date",
                render: (text, record: any) => (Moment(record.orddate).format("MM/DD/YYYY"))
              }
            },
            {field: "cutoffdate",
              columnProps: {
                title: "Cutoff Date",
                render: (text, record: any) => (Moment(record.cutoffdate).format("MM/DD/YYYY"))
              }
            },
            {field: "wantdate",
              columnProps: {
                title: "Wanted By",
                render: (text, record: any) => (Moment(record.wantdate).format("MM/DD/YYYY"))
              }
            },
            {field: "maildate",
              columnProps: {
                title: "Mail Date",
                render: (text, record: any) => (Moment(record.maildate).format("MM/DD/YYYY"))
              }
            },
            {field: "shipdate",
              columnProps: {
                title: "Ship Date",
                render: (text, record: any) => (Moment(record.shipdate).format("MM/DD/YYYY"))
              }
            },
            {field: "totQtyOrdered",
              columnProps: {
                title: "Qty Ordered",
                render: (text, record: any) => (record.totQtyOrdered == null ? 0 : record.totQtyOrdered.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {field: "totQtyReceived",
              columnProps: {
                title: "Qty Received",
                render: (text, record: any) => (record.totQtyReceived == null ? 0 : record.totQtyReceived.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {field: "totQtyConverted",
              columnProps: {
                title: "Qty Converted",
                render: (text, record: any) => (record.totQtyConverted == null ? 0 : record.totQtyConverted.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {
              columnProps: {
                render: (text, record: any) => (
                  <MailerReportsLink key={record.id} ordnum={record.id} bkrnum={record.bkrnum}/>
                )
              }
            }
          ]}
          onRowSelectionChange={this.handleRowSelectionChange}
          hideSelectionColumn={true}
          buttons={buttons}
        />
      </div>
    );
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const MailerMergesList = injectIntl(MergesListComponent);

export default MailerMergesList;
