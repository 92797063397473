import * as React from "react";

type Props = { list: string }
type State = { download : string, status: string, isLoaded: boolean }

export class CountsLink extends React.Component<Props, State> {

  _isMounted = false;

  constructor(props: Props) {
    super(props)
    this.state = {
      download:"https://storage.googleapis.com/website-paslists/counts/" + this.props.list + ".xlsx",
      status: "",
      isLoaded: false,
    }
  }

  componentDidMount() {

    this._isMounted = true;

    console.log("download url = " + this.state.download)
    fetch(this.state.download, {method: 'HEAD'})
      .then(res => {
        if (this._isMounted) {
          if (res.ok) {
            this.setState({
              isLoaded: true,
              status: 'url'
            })
          } else {
            this.setState({
              isLoaded: true,
              status: "No counts available"
            })
          }
        }
      }, err => {
        if (this._isMounted) {
          this.setState({
            status: "Error"
          })
          console.log("fetchCountsLink promise error: " + err)
        }
      }
    );
  }

  UNSAFE_componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    const {isLoaded, status, download} = this.state;

    if (!isLoaded) {
      return null;
    }
    else if (status === 'url') {
      return (
        <span><a target="_blank" rel="noopener noreferrer" href={download}>Counts</a></span>
      )
    } else {
      return (<div>{status}</div>)
    }
  }

}
