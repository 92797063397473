import { BaseStringIdEntity } from "./base/sys$BaseStringIdEntity";
export class Consultantmailer extends BaseStringIdEntity {
  static NAME = "pasweb_Consultantmailer";
  id?: string;
  consId?: any | null;
  consName?: string | null;
  cusId?: any | null;
  cusName?: string | null;
  numords?: any | null;
}
export type ConsultantmailerViewName = "_base" | "_local" | "_minimal";
export type ConsultantmailerView<
  V extends ConsultantmailerViewName
> = V extends "_base"
  ? Pick<
      Consultantmailer,
      "id" | "consId" | "consName" | "cusId" | "cusName" | "numords"
    >
  : V extends "_local"
  ? Pick<
      Consultantmailer,
      "id" | "consId" | "consName" | "cusId" | "cusName" | "numords"
    >
  : never;
