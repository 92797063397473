export enum MplistStatusEnum {
  UNRECEIVED = "UNRECEIVED",
  IN = "IN",
  READY_TO_TEST = "READY_TO_TEST",
  TESTING = "TESTING",
  TESTED = "TESTED",
  READY_TO_CONVERT = "READY_TO_CONVERT",
  CONVERTING = "CONVERTING",
  CONVERTED = "CONVERTED",
  OK = "OK",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
  IAC_SENT = "IAC_SENT",
  IAC_RECD = "IAC_RECD"
}

export enum OrderStatusEnum {
  in_credit = "in_credit",
  receiving_lists = "receiving_lists",
  ready_to_run = "ready_to_run",
  running = "running",
  errors = "errors",
  qa = "qa",
  ok_to_output = "ok_to_output",
  ok_to_ship = "ok_to_ship",
  shipped = "shipped",
  billed = "billed",
  hold = "hold",
  qty_check = "qty_check",
  qty_ng = "qty_ng",
  waiting_approval = "waiting_approval",
  output_ship_order = "output_ship_order",
  output_order = "output_order"
}

export enum WebUserTypes {
  MAILER = "MAILER",
  OWNER = "OWNER",
  MAILEROWNER = "MAILEROWNER",
  BROKER = "BROKER",
  BROKERMGR = "BROKERMGR",
  CONSULTANT = "CONSULTANT"
}
