import * as React from "react";
import {Webusers} from "../../cuba/entities/pasweb_Webusers";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react-core";
import {WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router";
import {observable} from "mobx";
import {DataCollectionStore} from "@cuba-platform/react-core/dist/data/Collection";
import {Mplists} from "../../cuba/entities/pasweb_Mplists";
import {DataTable} from "@cuba-platform/react-ui";
import Moment from "moment";
import {observer} from "mobx-react";
import {Ordstatus} from "../../cuba/entities/pasweb_Ordstatus";
import {Ordlines} from "../../cuba/entities/pasweb_Ordlines";
import {Button, Modal} from "antd";

type Props = { consId: string } & MainStoreInjected & WrappedComponentProps & RouteComponentProps;

@injectMainStore
@observer
export class ConsultantRentalSearchForm extends React.Component<Props> {

  @observable dataCollection: DataCollectionStore<Ordstatus>
  @observable searchMgrnum: string = ""
  @observable searchDecoy: string = ""
  @observable loading: boolean = false

  onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    if (ev.target.name === "searchMgrnum") this.searchMgrnum = ev.target.value
    if (ev.target.name === "searchDecoy") this.searchDecoy = ev.target.value
  }

  onReset = (e: React.FormEvent) => {
    e.preventDefault()
    this.searchMgrnum = ""
    this.searchDecoy = ""
    this.dataCollection?.clear()
    this.loading = false
  }

  rentalSearch = (e : React.FormEvent ) => {

    e.preventDefault();

    if (this.searchMgrnum === "" && this.searchDecoy === "") {
      this.dataCollection?.clear()
      this.loading = false
    } else if (this.searchMgrnum !== "" && this.searchDecoy !== "") {
      this.loading = false
    } else if (this.searchMgrnum !== "") {
      this.loading = true
      this.dataCollection = collection<Ordstatus>(Ordstatus.NAME, {
        view: "ordstatus-view",
        sort: "-order",
        filter: {conditions: [
            {property: "mgrnum", operator: "contains", value:this.searchMgrnum},
            {property: "order.cus.salesrepFk", operator: "=", value: this.props.consId }
          ]},
        loadImmediately: false
      })
      this.dataCollection.load().then(value => {
        this.loading = false
      })
    } else if (this.searchDecoy !== "") {
      this.loading = true
      this.dataCollection = collection<Ordstatus>(Ordstatus.NAME, {
        view: "ordstatus-view",
        sort: "-order",
        filter: {conditions: [
            {property: "mgrdecoy", operator: "contains", value: this.searchDecoy},
            {property: "order.cus.salesrepFk", operator: "=", value: this.props.consId }
          ]},
        loadImmediately: false
      })
      this.dataCollection.load().then(value => {
        this.loading = false
      })
    }
  }

  render() {

    const expandRow = ( ordnum: number, expanded:boolean ) => {

      if (expanded) {

        const dataCollection2 = collection<Ordlines>(Ordlines.NAME, {
          view: "ordlines-view",
          sort: "+linenum",
          filter: {
            conditions: [
              {property: "ordOrdnum", operator: "=", value: ordnum}
            ]
          }
        });

        return (<DataTable
          dataCollection={dataCollection2}
          canSelectRowByClick={false}
          tableProps={{bordered: true}}
          columnDefinitions={[
            {field: "keycode", columnProps: {title: "Keycode"}},
            {field: "orlDesc", columnProps: {title: "Segment"}},
            {field: "ordqty",
              columnProps: {
                title: "Order Qty",
                render: (text, record: any) => (record.ordqty == null ? 0 : record.ordqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {field: "shipqty",
              columnProps: {
                title: "Ship Qty",
                render: (text, record: any) => (record.shipqty == null ? 0 : record.shipqty.toLocaleString('en-US', {maximumFractionDigits: 0}))
              }
            },
            {field: "rentalemail",
              columnProps: {
                title: "Email",
                render: (text, record: any) => ( record.rentalemail?.id)
              }}
          ]}
          hideSelectionColumn={true}
        />);
      } else {
        return null;
      }
    }

    return (
        <div>
          <form id="RentalSearchForm" onSubmit={this.rentalSearch.bind(this)} onReset={this.onReset.bind(this)}>
            Manager Order Number:
            <input name = "searchMgrnum" id="searchMgrnum" type="text" value={this.searchMgrnum} onChange={this.onChange.bind(this)}/>
            OR Decoy Code:
            <input name = "searchDecoy" id="searchDecoy" type="text" value={this.searchDecoy} onChange={this.onChange.bind(this)}/>
            <Button htmlType = "submit" type="primary" loading={this.loading}>Search</Button>
            <Button htmlType="reset">Clear</Button>
          </form>
          { (this.searchMgrnum !== '' && this.searchDecoy !=='') ? <div><br/><h2>Please specify one of Manager Number or Decoy Code</h2></div> : null }
          <br/>
          {(this.dataCollection?.status === 'DONE') && (this.dataCollection?.count === 0) ?
            <div><h2>No orders found matching the requested criteria</h2></div>
            : null
          }
          {this.dataCollection && this.dataCollection.status === "DONE" && this.dataCollection.count && this.dataCollection.count > 0 ?
            <DataTable
              dataCollection={this.dataCollection}
              canSelectRowByClick={false}
              tableProps={{ bordered: true,
                expandedRowRender : (record : any, index, indent, expanded ) => { return expandRow(record.order.id, expanded)}
              }}
              columnDefinitions={[
                {field: "order", columnProps: { title: "PAS Num", render: (text, record: any) => ( record.order.id) }},
                {field: "mgrnum", columnProps: {title: "Mgr Num"}},
                {field: "bkrnum", columnProps: {title: "Broker PO"}},
                {field: "orddate", columnProps: { title: "Order Date", render: (text, record: any) => ( record.orddate == null? "": Moment(record.orddate).format("MM/DD/YYYY"))}},
                {field: "maildate", columnProps: { title: "Mail Date", render: (text, record: any) => ( record.maildate == null? "": Moment(record.maildate).format("MM/DD/YYYY"))}},
                {field: "wantdate", columnProps: { title: "Want Date", render: (text, record: any) => ( record.wantdate == null? "": Moment(record.wantdate).format("MM/DD/YYYY"))}},
                {field: "shipdate", columnProps: { title: "Ship Date", render: (text, record: any) => ( record.shipdate == null? "": Moment(record.shipdate).format("MM/DD/YYYY"))}},
                {field: "statdesc", columnProps: {title: "Status"}},
                {field: "pasdecoy", columnProps: {title: "PAS Decoy"}},
                {field: "mgrdecoy", columnProps: {title: "Mgr Decoy"}}
              ]}
              hideSelectionColumn={true}
            />
            : null
          }
      </div>
    )
  }
}
